import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { RuntimeEnv } from '@wss/config/wss-app-constants';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { MessageService } from '@wss/service/message.service';

@Component({
  selector: 'bb-eoc-return-vehicle-confirmation',
  templateUrl: 'eoc-return-vehicle-confirmation.component.html'
})

export class EocReturnVehicleConfirmationComponent implements OnInit {

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  handbookUrl: any;
  accountDetails: any;
  goodConditionUrlItem: any;
  dealersDetails: any;

  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    private datastoreService: DatastoreService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'End of Contract Return Vehicle Confirmation',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.dealersDetails = this.datastoreService.getDealersDetails();
    if (!this.dealersDetails?.dlr_type) {
      this.getDealersDetails();
    }
  }

  backToHomepage() {
    this.datastoreService.setEocRoute("landing");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/landing']);
  }

  goodConditionGuide() {
    this.endOfContractWidgetItem$.subscribe((res: any) => {
      this.goodConditionUrlItem = res?.eocGoodConditionGuide;
      this.navigateToGoodConditionGuide();
    })
  }

  navigateToGoodConditionGuide() {
    let portalName = RuntimeEnv.backbase ? window.BB.config.portalName : '';
    let url = this.eocService.getGoodConditionLink(portalName, this.dealersDetails?.dlr_type, this.goodConditionUrlItem, this.accountDetails.goods_code);
    window.location.href = url;
  }

  getDealersDetails() {
    this.eocService.getDealersDetails(this.accountDetails?.dealer_number)
      .subscribe((res: any) => {
        this.dealersDetails = res;
        this.changeDetectorRef.detectChanges();
      }, (error: any) => { })
  }
  downloadHandbook() {
    let portalName = RuntimeEnv.backbase ? window.BB.config.portalName : '';
    window.open(`/${portalName}/handback-checklist`);
  }
}
