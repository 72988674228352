import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { UserDetailService } from '@wss/header-content-widget/src/services/update-detail.services';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable, Subject } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { MessageService } from '@wss/service/message.service';
import { takeUntil } from 'rxjs/operators';
import { getErrorImageClass, isErrServer } from '@wss/common/util/util';
import { ServiceException } from '../../../../model/service-exception';
import { patternMap } from '../../../../config/wss-app-constants';
import { BannerMessage } from 'libs/model/bannerMessage';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { TealiumUtagService } from '@wss/service/utag.service';

@Component({
  selector: 'bb-eoc-contact-details',
  templateUrl: 'eoc-contact-details.component.html'
})
export class EocContactDetailsComponent implements OnInit {
  eocContactForm: any;
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  endOfContractWidgetItem: any;
  // isCorrectNumber: any;
  // newContactNo: any;
  customerDetail: any = {};
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  serviceException: ServiceException = new ServiceException();
  hasMobileNumberError: boolean = false;
  bannerMessage: BannerMessage = new BannerMessage();

  get f() { return this.eocContactForm.controls; }
  constructor(
    private router: Router,
    private readonly bbContentService: ContentService,
    private dataStore: DatastoreService,
    private UserDetaillSrvc: UserDetailService,
    private eocService: EndOfContractService,
    private messageService: MessageService,
    public changeDetectorRef: ChangeDetectorRef,
    private datastoreService: DatastoreService,
    private tealiumService: TealiumUtagService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.tealiumService.view (
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Contact Details',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.customerDetail = this.dataStore.getCustomerDetail();
    this.eocContactForm = this.fb.group({
      isCorrectNumber: [""],
      newContactNo: ["", [Validators.required]]
    });
  }

  navigatetoConfirmation() {
    this.datastoreService.setEocRoute("return-vehicle-confirmation");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/return-vehicle-confirmation']);
    window.scroll(0, 0);
  }

  updateContact() {
    let newContactNoValue = this.eocContactForm.get('newContactNo').value;
    let formattedPhoneNumber = newContactNoValue.replace("+44", "");
    if (formattedPhoneNumber[0] !== "0") {
      formattedPhoneNumber = "0" + formattedPhoneNumber;
    }
    let obj = { mobile_phone_number: "" }
    obj.mobile_phone_number = formattedPhoneNumber;
    this.UserDetaillSrvc.UpdateContact(obj).subscribe((data) => {
      this.saveOption();
    },
      (error: any) => {
        this.showError(error?.status);
      },
    )
  }

  saveOption() {
    this.eocService.saveReturnVehicleOption()
      .subscribe((res: any) => {
        this.navigatetoConfirmation();
      }, (error: any) => {
        this.datastoreService.setEocRoute("return-vehicle");
        this.messageService.setActiveNav("End of contract");
         this.router.navigate(['/eoc-options/return-vehicle']);
      });
  }

  confirm() {
    if (this.eocContactForm.get('isCorrectNumber').value === "no") {
      this.updateContact();
    }
    else {
      this.saveOption();
    }
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showError(error?.status);
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus) || errStatus == 506) {
      this.bannerMessage.closeIconClass = 'clear';
      this.bannerMessage.infoTypeClass = 'error-icon';
      this.bannerMessage.leftSectionColorClass = 'message-failure';
      this.endOfContractWidgetItem$.subscribe((res: any) => {
        this.endOfContractWidgetItem = res;
        this.bannerMessage.titleText = this.endOfContractWidgetItem?.endOfContractContactDetails.eocErrorbannerMessage.titleTextBeforeData;
        this.bannerMessage.subtitleText = this.endOfContractWidgetItem?.endOfContractContactDetails.eocErrorbannerMessage.subtitleTextBeforeData + this.endOfContractWidgetItem?.brandContactDetails.brandWiseContactNumber + this.endOfContractWidgetItem?.endOfContractContactDetails.eocErrorbannerMessage.subtitleTextAfterData;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.serviceError = true;
      }
      )
    }
    else {
      this.serviceError = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showHideError() {
    this.hasMobileNumberError = false;
    const mobilenumberValue = this.eocContactForm.get('newContactNo').value;
    const mobileNumberRegex = patternMap.ukMobileAndLandlinePattern;
    if (!mobilenumberValue || mobilenumberValue?.match(mobileNumberRegex)) {
      this.hasMobileNumberError = false;
    } else {
      this.hasMobileNumberError = true;
      return;
    }
  }

}
