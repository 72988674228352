import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { appConfig, creditcheckPlansDev, creditcheckPlansQa, creditcheckPlansProd } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { Title } from '@angular/platform-browser';
import { getCompanyTitle } from '@wss/common/util/header-util';
import { TealiumUtagService } from '@wss/service/utag.service';
import { DealerplanService } from '@wss/service/dealerplan.service';
import { StorageService } from '@wss/service/storage.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '@wss/login-widget/src/service/login.service';
import { Subject } from 'rxjs';
import { PAGE_CONFIG, PageConfig } from '@backbase/foundation-ang/web-sdk';
import { DataService } from '@wss/complaints-widget/src/services/data.service';
import { CookieService } from 'ngx-cookie-service';
import { envType } from '@wss/common/util/util';

declare let document: any;

@Component({
  selector: 'bb-wss-app',
  template: '<bb-root></bb-root>',
})
export class AppComponent implements OnInit, OnDestroy {

  title: string = 'Page Title';
  urlLoc = window.location.href;
  loggedInCustomerDetails: any = {};
  serviceError: boolean = false;
  authorisation: any;
  customerDetailResponse: boolean = false;
  readonly destroy$ = new Subject();
  portalName: string = this.pageConfig.portalName || 'blackhorse';
  customerId: any;
  accountNumber: any;
  isArchived: any;
  username: any;
  authToken: string = '';

  constructor(
    private bnIdle: BnNgIdleService,
    private datastoreService: DatastoreService,
    private titleService: Title,
    private tealiumService: TealiumUtagService,
    private dealerplanService: DealerplanService,
    private renderer: Renderer2,
    private storageService: StorageService,
    private loginService: LoginService,
    private routerAct: ActivatedRoute,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private formDataService: DataService,
    private cookieService: CookieService
  ) {
    if (this.urlLoc.indexOf('authorization') !== -1 || this.urlLoc.indexOf('customerId') !== -1) {
      this.datastoreService.sessionLogin();
      this.cookieService.delete('Authorization');
      localStorage.setItem('loggedIn', 'true');
      this.customerId = this.urlLoc.split('customerId=')[1].split('&')[0];
      this.accountNumber = this.urlLoc.split('accountNumber=')[1].split('&')[0];
      this.isArchived = this.urlLoc.split('isArchived=')[1].split('&')[0];
      this.username = this.urlLoc.split('username=')[1].split('&')[0];
      if (envType() == appConfig.envName.dev || envType() == appConfig.envName.sit) {
        this.authToken = this.urlLoc.split('authorization=')[1].split('&')[0];
        document.cookie = this.authToken.length > 0 ? 'Authorization=' + this.authToken + '; path=/;' : '';
      }
      (async () => {
        await this.loginService.getCustomerDetail(this.customerId).toPromise();
        await this.loginService.getCustomerId().toPromise();
      })();
      localStorage.setItem('MTAredirection', 'true');
      this.datastoreService.setLoggedInEmail(this.username.toLowerCase());
      this.storageService.setLocalStorage('accountNo', this.accountNumber);
      this.storageService.setLocalStorage('customerId', this.customerId);
      this.datastoreService.setUserName(this.username);
      this.datastoreService.setAccountNo(this.accountNumber);
      this.redirectionCall();
    }
    this.loggedInCustomerDetails = this.storageService.getLocalStorage('customerDetail');
    this.loggedInCustomerDetails = this.loggedInCustomerDetails && JSON.parse(this.loggedInCustomerDetails);
    if (!this.loggedInCustomerDetails) {
      let concernType: any = {}
      this.routerAct.queryParams.subscribe(params => {
        let urlQueryParam = '';
        if (params['redirectTo']) {
          urlQueryParam = atob(decodeURIComponent(params['redirectTo'])) ? atob(decodeURIComponent(params['redirectTo'])) : params['redirectTo'];
        }
        if (urlQueryParam === 'settlement') {
          localStorage.setItem('redirectionFlag', 'settlement');
          document.getElementById("appLoader").style.display = "block";
          document.querySelector("body").classList.add("block-ui");
          localStorage.setItem('settlementRedirectionFlag', 'true');
        } else if (urlQueryParam === 'makePaymentFAQs') {
          localStorage.setItem('makePaymentFAQsRedirection', 'true');
        }
        else if (urlQueryParam === 'getInTouch') {
          localStorage.setItem('getInTouchRedirection', 'true');
        }
        else if (urlQueryParam == 'voluntary-termination') {
          localStorage.setItem('voluntaryTerminationRedirection', 'true');
        }
        else if ((urlQueryParam === 'complaints' && atob(decodeURIComponent(params['complaintsJourney'])) === 'Vehicle')
          || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Vehicle')) {
          concernType = { concernType: 'Vehicle' };
          localStorage.setItem('complaintsRedirection', 'true');
          this.formDataService.saveFormData('concernSelectionForm', concernType);
        } else if ((urlQueryParam === 'complaints' && atob(decodeURIComponent(params['complaintsJourney'])) === 'Others')
          || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Others')) {
          concernType = { concernType: 'FinanceAgreement' };
          localStorage.setItem('complaintsRedirection', 'true');
          this.formDataService.saveFormData('concernSelectionForm', concernType);
        }
      });
    }
    this.setTealiumConfig();
    this.setDealerPlans();
    this.renderer.addClass(document.body, 'page-loaded');
  }

  ngOnInit(): void {
    if (this.urlLoc.indexOf(appConfig.welcomeURL) === -1) {
      this.setTitle();
    }
    if ((this.urlLoc.indexOf(appConfig.loginURL) === -1) && (this.urlLoc.indexOf(appConfig.regURL) === -1)
      && (this.urlLoc.indexOf(appConfig.welcomeURL) === -1)) {
      this.bnIdle.startWatching(appConfig.appTimeout).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.datastoreService.sessionLogout();
        }
      });
    } else {
      if (localStorage.getItem('loggedIn') != 'true') {
        this.datastoreService.sessionLogin();
      }
    }
    document.getElementById("appLoader").style.display = "none";
  }

  redirectionCall() {
    if (this.urlLoc.indexOf(appConfig.dashboardURL) === -1) {
      if (this.isArchived === 'true') {
        this.datastoreService.setIsAccountArchived(true);
        location.href = appConfig.mailboxURL;
      } else {
        location.href = appConfig.dashboardURL;
      }
    }
  }

  public setTitle() {
    this.title = getCompanyTitle();
    this.titleService.setTitle(this.title);
  }

  setTealiumConfig() {
    let loc = window.location.href;
    let envName = '';
    if (loc.indexOf(appConfig.envPhrase.dev) !== -1) {
      envName = appConfig.envName.dev;
    } else if (loc.indexOf(appConfig.envPhrase.sit) !== -1) {
      envName = appConfig.envName.sit;
    } else if (loc.indexOf(appConfig.envPhrase.preprod) !== -1) {
      envName = appConfig.envName.preprod;
    } else {
      envName = appConfig.envName.prod;
    }
    this.tealiumService.setConfig({ account: appConfig.tealiumAccount, profile: appConfig.tealiumProfile, environment: envName });
  }

  setDealerPlans() {
    let loc = window.location.href;
    let envName = '';
    if (loc.indexOf(appConfig.envPhrase.dev) !== -1) {
      this.dealerplanService.setDealerPlan(creditcheckPlansDev.carDealer, creditcheckPlansDev.bikeDealer);
      envName = appConfig.envName.dev;
    } else if ((loc.indexOf(appConfig.envPhrase.sit) !== -1) || (loc.indexOf('localhost') !== -1)) {
      this.dealerplanService.setDealerPlan(creditcheckPlansDev.carDealer, creditcheckPlansDev.bikeDealer);
    } else if (loc.indexOf(appConfig.envPhrase.preprod) !== -1) {
      this.dealerplanService.setDealerPlan(creditcheckPlansQa.carDealer, creditcheckPlansQa.bikeDealer);
    } else {
      this.dealerplanService.setDealerPlan(creditcheckPlansProd.carDealer, creditcheckPlansProd.bikeDealer);
    }
  }

  ngOnDestroy(): void {
    localStorage.setItem('loggedIn', 'false');
    localStorage.removeItem('loggedInEmail');
    this.renderer.removeClass(document.body, 'page-loaded');
  }
}
