<div class="common-contract-details additional-info-container">
    <div test_id="agreementInfo" class="mt-20px font-size-20">
        <bb-content-html [html]="additionalInfoContent.agreementInfo"></bb-content-html>
    </div>
    <div (ngInit)="resetIndex()" class="container-column additional-info-content-margin">

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="startDate">
                    <bb-content-html [html]="additionalInfoContent.startDate"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.inception_date}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="endDate">
                    <bb-content-html [html]="additionalInfoContent.endDate"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.final_payment_date}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="contractLength">
                    <bb-content-html [html]="additionalInfoContent.contractLength"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">
                {{accountDetail?.original_term}} <span class="para-inline"><bb-content-html [html]="additionalInfoContent.monthText"></bb-content-html></span>
        </span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="paymentMade">
                    <bb-content-html [html]="additionalInfoContent.paymentMade"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.original_term - accountDetail?.num_payments_left}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="monthlyPayment">
                    <bb-content-html [html]="additionalInfoContent.monthlyPayment"></bb-content-html>
                </span>
            </span>
            <span class="flex-width">{{accountDetail?.next_payment_amount}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <div class="flex-width additional-info-item-pull-right content-center">
                    <!-- <strong test_id="apr_label_id">{{content?.apr}}</strong> -->
                    <span class="para-inline" test_id="apr">
                        <bb-content-html [html]="additionalInfoContent.apr"></bb-content-html>
                        <svg id="aprPopId" [ngbPopover]="popContentApr" autoClose="outside" triggers="manual" #pApr="ngbPopover"
                        (click)="openPopover(pApr, $event, 'aprPopId')" popoverClass="popover-manage-contract" title="info-icon"
                        class="svg-margin-left info-icon-top" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
                    </span>
                </div>
            </span>
            <span class="flex-apr-value" test_id="apr_value_id">{{accountDetail?.apr}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2" *ngIf="getHPCustomer()">
            <span class="info-item-left">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileage">
                    <bb-content-html [html]="additionalInfoContent.mileage"></bb-content-html>
                </span>
            </span>
            <span class="flex-width" test_id="annual_mileage_value_id">{{accountDetail?.max_annual_mileage}}</span>
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2" *ngIf="isLPAgreementFlag || ((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">
            <span class="info-item-left" *ngIf="((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileage">
                    <bb-content-html [html]="additionalInfoContent.optionalFinalPayment"></bb-content-html>
                    <svg
                    id="ofpPopId" 
                    [ngbPopover]="popContentOfp" 
                    autoClose="outside" 
                    triggers="manual" 
                    #pOfp="ngbPopover"
                    (click)="openPopover(pOfp, $event, 'ofpPopId')" 
                    popoverClass="popover-ofp"
                    title="info-icon" 
                    class="svg-margin-left info-icon-top mt-0"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" /></svg>
                </span>
            </span>
            <span class="info-item-left" *ngIf="isLPAgreementFlag">
                <span class="flex-width additional-info-item-pull-right para-inline" test_id="mileageLP">
                    <bb-content-html [html]="additionalInfoContent.FinalPaymentLP"></bb-content-html>                    
                </span>
            </span>
            <span class="flex-width" test_id="annual_mileage_value_id" *ngIf="isLPAgreementFlag || ((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">{{accountDetail?.final_payment_amount}}</span>            
        </div>

        <div [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2" 
            *ngIf="((accountDetail?.scheme_code === '54' || accountDetail?.scheme_code === 54) && getHPCustomer())">
            <span class="info-item-left">
                <div class="flex-width additional-info-item-pull-right content-center">
                    <span class="para-inline" test_id="mileageId">
                        <bb-content-html [html]="additionalInfoContent.excessMileageChargeRowName"></bb-content-html>
                        <svg id="mileagePopId" [ngbPopover]="mileagePopUpContent" autoClose="outside" triggers="manual" #mileagePopUp="ngbPopover"
                        (click)="openPopover(mileagePopUp, $event, 'aprPopId')" popoverClass="popover-manage-contract" title="info-icon"
                        class="svg-margin-left info-icon-top" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
                    </span>
                </div>
            </span>
            <span class="flex-apr-value" test_id="mileage_value_id">{{excessMileageCharge | currency:'GBP':'symbol'}}</span>
        </div>

        <div *ngIf="accountDetail.regulated_ind" [ngClass]="getClass()" class="container-row border-bottom content-center additional-info-item-height p-2">
            <span class="info-item-left">
                <div class="flex-width additional-info-item-pull-right content-center">
                    <span class="para-inline" test_id="vtId">
                        <bb-content-html [html]="additionalInfoContent.voluntaryTerminationRowName"></bb-content-html>
                        <svg id="vTPopUpId" [ngbPopover]="vTPopUpContent" autoClose="outside" triggers="manual" #vtPopUp="ngbPopover"
                        (click)="openPopover(vtPopUp, $event, 'aprPopId')" popoverClass="popover-manage-contract" title="info-icon"
                        class="svg-margin-left info-icon-top" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 24 24">
                        <path [attr.fill]="iconColor" [attr.d]="svgInfoIcon" />
                    </svg>
                    </span>
                </div>
            </span>
            <span *ngIf="vtFlag && !isBereavementFileTag" class="flex-apr-value" test_id="vt_value_id"><a href="#/eoc-options/voluntary-termination">{{vtValue}}</a></span>
            <span *ngIf="!vtFlag && !isBereavementFileTag" class="flex-apr-value" test_id="vt_value_id">
                <bb-content-html [html]="additionalInfoContent.cannotCalcVTValueText"></bb-content-html>
            </span>
            <span *ngIf="isBereavementFileTag" class="flex-apr-value" test_id="vt_value_id">
                <bb-content-html [html]="additionalInfoContent.bereavementFileTagVTValueText"></bb-content-html>
            </span>
        </div>

    </div>
</div>

<ng-template #popContentApr>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="aprTooltipContent">
            <bb-content-html [html]="additionalInfoContent.aprTooltipContent"></bb-content-html>
            
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>

<ng-template #popContentOfp>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body pover-over-class">
        <div class="content" test_id="ofpTooltipContent" *ngIf="additionalInfoContent.ofpTooltipContent">
            <bb-content-html [html]="additionalInfoContent.ofpTooltipContent"></bb-content-html>
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>

<ng-template #vTPopUpContent>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="vtTooltipContent">
            <bb-content-html [html]="additionalInfoContent.voluntaryTerminationToolTipContent"></bb-content-html>
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>


<ng-template #mileagePopUpContent>
    <span class="tooltip-arrow-up" [attr.style]="popoverArrowStyle"></span>
    <div class="container-row modal-body">
        <div class="content" test_id="excessMileageTooltipContent">
            <bb-content-html [html]="additionalInfoContent.excessMileageToolTipContent"></bb-content-html>
        </div>
        <div class="tooplip-close">
            <span (click)="closePopover()" class="material-icons clear-icon"
                [attr.style]="tooltipCloseStyle">clear</span>
        </div>
    </div>
</ng-template>