import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/content-ang';
import { appConfig, WSSAppConstant } from '@wss/config/wss-app-constants';
import { ManageContractAdditionalInfo, ManageContractSummary } from '@wss/model/content/common/contract-details';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { 
  injectGoodConditionGuideIntoPage, 
  isPcpToHpBalloonRefinance, 
  getIconColor,
  isLPAgreement } from '@wss/common/util/util';
 // suzuki spacing fix draft1
// import { unicodeSpaceFixFromSuzukiContent } from '@wss/common/util/util';

@Component({
  selector: 'bb-manage-contract',
  templateUrl: './manage-contract.component.html'
})

export class ManageContractComponent implements OnInit {
  accountDetails: any = {};
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  goodConditionGuideUrlContent: any;
  goodConditionGuideUrl: any;
  isPcpJourney: boolean = false;
  dealersDetails: any;
  manageContractContent: any;
  contractSummary: ManageContractSummary = {
    additionalInfoData: new ManageContractAdditionalInfo()
  };
  isPcpToHp: any;
  svgIconColor = getIconColor();
  isLPAgreementFlag: any;
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();

  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');

  constructor(
    private datastoreService: DatastoreService,
    private router: Router,
    private bbContentService: ContentService,
    private eocService: EndOfContractService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Manage Contract',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.changeDetectorRef.detectChanges();
    this.dealersDetails = this.datastoreService.getDealersDetails();
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.isPcpJourney = !(this.eocService.isPCPBalloonRefinanceCustomer() || this.accountDetails?.scheme_code === appConfig.hpCustomer || this.accountDetails?.scheme_code === '01' || isPcpToHpBalloonRefinance(this.accountDetails))
    this.isPcpToHp = isPcpToHpBalloonRefinance(this.accountDetails);
    this.isLPAgreementFlag = isLPAgreement(this.accountDetails);
    this.endOfContractWidgetItem$.subscribe((res: any) => {
      // suzuki spacing fix draft1
      // unicodeSpaceFixFromSuzukiContent(res?.manageContract);
      this.goodConditionGuideUrlContent = res?.eocGoodConditionGuide;
      this.endOfContractWidgetItem = res;
      if (!this.dealersDetails?.dlr_type) {
        this.getDealersDetails(res?.manageContract);
      } else {
        this.setGoodConditionUrl(res?.manageContract);
      }
      this.changeDetectorRef.detectChanges();
    })
    
  }

  setGoodConditionUrl(manageContractContent: any) {
    let portalName = window.BB?.config.portalName;
    this.goodConditionGuideUrl = this.eocService.getGoodConditionLink(portalName, this.dealersDetails?.dlr_type, this.goodConditionGuideUrlContent, this.accountDetails.goods_code);
    this.endOfContractWidgetItem.manageContract = injectGoodConditionGuideIntoPage(manageContractContent, this.goodConditionGuideUrl);
  }

  getDealersDetails(content: any) {
    this.eocService.getDealersDetails(this.accountDetails?.dealer_number)
      .subscribe((res: any) => {
        this.dealersDetails = res;
        this.setGoodConditionUrl(content);
        this.changeDetectorRef.detectChanges();
      }, (error: any) => { })
  }

}
