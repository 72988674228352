import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BalloonRefinanceService } from '../../services/balloon-refinance.service';
import { BalloonRefinanceActions, LoadVehicleAction} from "../../../../store/balloonRefinance/balloonRefinance.action";
import { StoreFactory, Store } from '@backbase/foundation-ang/store';
import { balloonRefinanceReducer, UserDetailsState } from '@wss/store/balloonRefinance/balloonRefinance.reducer';
import { Observable } from 'rxjs';
import { VehicleState } from '@wss/store/balloonRefinance/balloonRefinance.state';
import { BalloonRefinanceEffects } from '@wss/store/balloonRefinance/balloonRefinance.effect';

@Component({
  selector: 'bb-br-update-vehicle-details',
  templateUrl: 'br-update-vehicle-details.component.html',
  styles: [
  ]
})
export class BrUpdateVehicleDetailsComponent implements OnInit {

  isVehicleRegistrationNoCorrect: any;
  vehicleData$: Observable<any>;
  private readonly store: Store<VehicleState, BalloonRefinanceActions> = this.storeFactory.createStore(
    'vehicle',
    balloonRefinanceReducer,
    [BalloonRefinanceEffects],
  );

  constructor(private router: Router,
    private balloonRefinanceService: BalloonRefinanceService,
    private changeDetectorRef: ChangeDetectorRef,
    private storeFactory: StoreFactory
    ) { 
      this.vehicleData$ = this.store.select(store => store.vehicle);
    }

  ngOnInit(): void {
    
  }

  navigate(path: string): void {
    this.router.navigate(['/balloon-refinance/' + path]);
  }

  onVehicleRadioChange() {
    if(this.isVehicleRegistrationNoCorrect === 'yes') {
      this.store.dispatch(new LoadVehicleAction());
      this.changeDetectorRef.detectChanges();
    }
  }

}
