import { BrandContactDetailsItem } from '@wss/model/content/common/brand-contant-details';
import { WSSAccordion } from "../balloon-refinance/balloon-refinance";

export class EndOfContractItem {
    endOfContractLanding = new EndOfContractLandingItem();
    endOfContractReturnVehicle = new EndOfContractReturnVehicleItem();
    endOfContractContactDetails = new EndOfContractContactDetails();
    endOfContractReturnVehicleConfirmation = new EndOfContractReturnVehicleConfirmationItem;
    endOfContractKeepVehicle = new EndOfContractKeepVehicleItem()
    endOfContractKeepVehicleConfirmation = new EndOfContractKeepVehicleConfirmationItem();
    endOfContractPartExchange = new EndOfContractPartExchangeItem();
    endOfContractPartExchangeConfirmation = new EndOfContractPartExchangeConfirmationItem();
    endOfContractHp = new EndOfContractHpItem();
    endOfContractGetInTouch = new EndOfContractGetInTouchItem();
    eocGoodConditionGuide = new GoodConditionGuideItem();
    updateContacts = new UpdateContactItem();
    manageContract = new ManageContractItem();
    brandContactDetails = new BrandContactDetailsItem();
    eocTrafficLight = new EocTrafficLightItem();
    voluntaryTermination = new VoluntaryTerminationItem();
    eocVoluntaryTerminationCtaPage = new EocVoluntaryTerminationCtaPageItem()
}

export class EndOfContractLandingItem {
    pageName: string = "";
    agreementEndTextBeforeDate: string = "";
    agreementEndTextAftereDate: string = "";
    agrmtEndDetailsSecFourInNinety: string = "";
    agrmtEndDetailsSecFourOutNinety: string = "";
    agrmtEndDetailsSecThreeInNinety: string = "";
    agrmtEndDetailsSecThreeOutNinety: string = "";
    agrmtEndDetailsThirdPtFourInNinety: string = "";
    agrmtEndDetailsThirdPtThreeInNinety: string = "";
    agrmtEndDetailsThirdPtThreeOutNinety: string = "";
    agrmtEndDetailsThirdPtFourOutNinety: string = "";
    agreementIntentionBeforeDate: string = "";
    agreementIntentionAfterDateKeepVehicle: string = "";
    agreementIntentionAfterDateReturnVehicle: string = "";
    agreementIntentionAfterDatePartExchange: string = "";
    agreementIntentionAfterBalloonRefinanceWithLink: string = "";
    agreementIntentionAfterBalloonRefinanceWithoutLink: string = "";
    optionReminderText: string = "";
    optionsHeader: string = "";
    firstOptionHeading: string = "";
    keepYourVehicle: string = "";
    firstOptionHeaderText: string = "";
    firstOptionGlanceBeforeDataText: string = "";
    firstOptionGlanceAfterAmountDataText: string = "";
    firstOptionGlanceAfterDateDataText: string = "";
    firstOptionGlance2ndPointText: string = "";
    firstOptionSecondndHeaderText: string = "";
    keepMyVehicleButtonText: string = "";
    secondOptionHeading: string = "";
    partExchange: string ="";
    secondOptionHeaderText: string = "";
    secondOptionGlanceInfoText: string = "";
    secondOptionSecondHeaderText: string = "";
    partExchangeButtonText: string = "";
    thirdOptionHeading: string = "";
    balloonRefinance: string = "";
    balloonRefinanceHeaderText: string = "";
    balloonRefinanceOptionGlanceFirstPointText: string = "";
    balloonRefinanceOptionGlanceSecondPointText: string = "";
    balloonRefinanceKeepInMindText: string = "";
    balloonRefinanceButtonText: string = "";
    fourthOptionHeading: string = "";
    returnVehicle: string = "";
    ThirdOptionHeaderText: string = "";
    ThirdOptionGlanceInfoText: string = "";
    ThirdOptionSecondHeaderText: string = "";
    retrunYourVehicleButtonText: string = "";
    contactDetailHeaderText: string = "";
    callUsText: string = "";
    callTimingDetailsText: string = "";
    callCostingDetailsText: string = "";
    currentOngoingRefinanceApplicationText: string = "";
    continueApplicationLinkText: string = "";
    returnAndSignText: string = "";
    reviewYourApplicationResultText: string ='';
}

export class EndOfContractReturnVehicleItem {
    pageName: string = "";
    textBelowPageHeader: string = "";
    howItWorksText: string = "";
    howItWorksFirstPointHeader: string = "";
    howItWorksFirstPointContent: string = "";
    howItWorksSecondPointHeader: string = "";
    howItWorksSecondPointContentBeforeData: string = "";
    goodConditionguideLink: string = "";
    howItWorksThirdPointHeader: string = "";
    howItWorksThirdPointContent: string = "";
    whatYouNeedToKnowText: string = "";
    firstAccordionHeader: string = "";
    firstAccordionFirstSectionBeforeData: string = "";
    firstAccordionFirstSectionAfterData: string = "";
    firstAccordionSecondSectionBeforeData: string = "";
    firstAccordionSecondSectionAfterData: string = "";
    secondAccordionHeader: string = "";
    secondAccordionFirstSectionBeforeData: string = "";
    secondAccordionFirstSectionAfterData: string = "";
    secondAccordionSecondSectionDetails: string = "";
    thirdAccordionHeader: string = "";
    thirdAccordionDetails: string = "";
    fourthAccordionHeader: string = "";
    fourthAccordionDetails: string = "";
    fifthAccordionHeader: string = "";
    fifthAccordionDetails: string = "";
    wantToReturnVehicleText: string = "";
    returnVehicleButtonText: string = "";
    backToOptionLink: string = "";
    haveQuestionsText: string = "";
    confirmPopupText: string = "";
    confirmButtonText: string = "";
    backButtonText: string = "";
    eocErrorbannerMessage:string="";
    optionDisableInfoText: string = "";
    mileageChargeUnit: string = ""

}
export class EndOfContractContactDetails {
    pageName: string = "";
    textBelowPageHeader: string = "";
    collectionTextBeforeData: string = "";
    collectionTextAfterData: string = "";
    contactNumberQuestionText: string = "";
    yesText: string = "";
    noText: string = "";
    continueButtonText: string = "";
    cancelLinkText: string = "";
    anotherContactNumberText: string = "";
    withIn24HrsException: string = "";
    updateFailureException: string = "";
    mobileNumberErrorMessageText: string = "";
    eocErrorbannerMessage:string = "";
}

export class EndOfContractReturnVehicleConfirmationItem {
    optionSelectionText: string = "";
    // nextStepContent: string = "";
    nextStepText: string = "";
    firstStepContent: string = "";
    secondStepContentBeforeData: string = "";
    thirdStepContent: string = "";
    handbackguidelink: string = "";
    checkDamageChargesButton: string = "";
    whatHappensWhenWeCollectVehicleContentFirstData: string = "";
    whatHappensWhenWeCollectVehicleContentBeforeData: string = "";
    goodConditionguideLink: string = "";
    whatHappensWhenWeCollectVehicleContentAfterData: string = "";
    whatHappensWhenWeCollectVehicleContentSecondData: string = "";
    downloadHandbookButtonText: string = "";
    ifYouChangeYourMindContent: string = "";
    backToHomepage: string = "";
}

export class EndOfContractKeepVehicleItem {
    pageName: string = "";
    textBelowPageHeaderFirstSection: string = "";
    textBelowPageHeaderSecondSection: string = "";
    howItWorksText: string = "";
    howItWorksFirstPoint: string = "";
    howItWorksSecondPointHeader: string = "";
    howItWorksSecondPointText: string = "";
    howItWorksThirdPoint: string = "​";
    howItWorksFourthPoint: string = "";
    whatYouNeedToKnowText: string = "";
    firstAccordionHeader: string = "";
    firstAccordionContent: string = "";
    secondAccordionHeader: string = "";
    secondAccordionContent: string = "";
    thirdAccordionHeader: string = "";
    thirdAccordionContent: string = "";
    fourthAccordionHeader: string = "";
    fourthAccordionContent: string = "";
    makeYourSelectionContent: string = "";
    keepMyVehicleButtonText: string = "";
    backToOptionsLinkText: string = "";
    haveQuestionsText: string = "";
    justToConfirm: string = "";
    confirmPopupText: string = "";
    confirmButtonText: string = "";
    backButtonText: string = "";
    eocErrorbannerMessage:string = "";
    optionDisableInfoText: string = "";
}

export class EndOfContractKeepVehicleConfirmationItem {
    optionSelectionText: string = "";
    nextStepText: string = "";
    firstStepHeaderBeforeData: string = "";
    firstStepHeaderAfterData: string = "";
    textBetweenAmountAndDate: string = "";
    firstStepContent: string = "";
    secondStepContent: string = "";
    thirdStepContent: string = "";
    thingsNeedToKnowContent: string = "";
    ifYouChangeYourMindContent: string = "";
    backToHomepage: string = "";
}

export class EndOfContractPartExchangeItem {
    pageName: string = "Part exchange";
    textBelowPageHeader: string = "";
    howItWorksText: string = "";
    settlementTextBeforeBrand: string = "abc";
    settlementTextAfterBrand: string = "abc";
    howItWorksFirstPointHeader: string = "";
    howItWorksFirstPointContent: string = "";
    howItWorksSecondPointHeader: string = "";
    howItWorksSecondPointContent: string = "";
    howItWorksThirdPointHeader: string = "";
    howItWorksThirdPointBeforeData: string = "";
    howItWorksThirdPointBetweenData: string = "";
    howItWorksThirdPointAfterData: string = "";
    howItWorksFourthPointHeader: string = "";
    howItWorksFourthPointContent: string = "";
    whatYouNeedToKnowText: string = "";
    firstAccordionHeader: string = "";
    firstAccordionDetails: string = "";
    secondAccordionHeader: string = "";
    secondAccordionDetails: string = "";
    thirdAccordionHeader: string = "";
    thirdAccordionDetails: string = "";
    fourthAccordionHeader: string = "";
    fourthAccordionDetails: string = "";
    fifthAccordionHeader: string = "";
    fifthAccordionDetails: string = "";
    wantToPartExchangeVehicleText: string = "";
    partExchangeVehicleButtonText: string = "";
    backToOptionLink: string = "";
    haveQuestionsText: string = "";
    confirmPopupText: string = "";
    confirmButtonText: string = "";
    backButtonText: string = "";
    eocErrorbannerMessage:string = "";
    optionDisableInfoText: string = "";
}
export class EndOfContractPartExchangeConfirmationItem {
    optionSelectionText: string = "";
    nextStepText: string = "";
    firstStepContent: string = "";
    secondStepHeader: string = "";
    secondStepContentBeforeData: string = "";
    secondStepContentBetweenData: string = "";
    thirdStepHeader: string = "";
    thirdStepContent: string = "";
    thingsNeedToKnowHeader: string = "";
    thingsNeedToKnowContent: string = "";
    thingsNeedToKnowFirstData: string = "";
    thingsNeedToKnowSecondData: string = "";
    thingsNeedToKnowThirdData: string = "";
    thingsNeedToKnowFourthPointBeforeData: string = "";
    thingsNeedToKnowFourthPointAfterData: string = "";
    ifYouChangeYourMindContent: string = "";
    backToHomepage: string = "";
}

export class GoodConditionGuideItem {
    blackHorseAllTypesMotorVehicle: string = "";
    blackhorseBikeScooter: string = "";
    blackhorseMotorhome: string = "";
    blackhorseTouringCarvan: string = "";
    blackhorseTaxi: string = "";
    blackhorseOffshoreScooter: string = "";
    blackhorseOffshoreBikeScooter: string = "";
    jaguar: string = "";
    landRover: string = "";
    mitsubishi: string = "";
    halifax: string = "";
    lloyds: string = "";
    bankOfScotland: string = "";
    suzukiCar: string = "";
    suzukiBike: string = "";
    imf: string = "";
}
export class EndOfContractHpItem {
    pageName: string = "";
    subTitleText: string = "";
    agreementEndContentBeforeDataText: string = "";
    agreementEndContentAfterDataText: string = "";
    agreementEndStepsInfoText: string = "";
    questionsInfoText: string = "";
    subSubTitleText: string = "";
    settlementOptionInfoText: string = "";
    settlementQuoteButtonText: string = "";
}

export class EndOfContractGetInTouchItem {
    title: string = "";
    subTitle: string = "";
    buttonLabel: string = "";
    backToOptionsLinkText: string = "";
    getInTouchDescriptionForIntention: string = "";
    hpOver90DaysTittleText: string = "";
    HPOver90DaysSubTitleTextBeforeData: string = "";
    HPOver90DaysSubTitleTextAfterData: string = "";
}

export class ManageContractItem {
    pageTitle: string = "";
    agreementInfo: string = "";
    agreementType: string = "";
    vehicleType: string = "";
    startDate: string = "";
    endDate: string = "";
    contractLength: string = "";
    paymentMade: string = "";
    monthlyPayment: string = "";
    optionalFinalPayment: string = "";
    FinalPaymentLP: string = "";
    apr: string = "";
    aprTooltipContent: string = "";
    ofpTooltipContent: string = "";
    excessMileageChargeRowName: string = "";
    excessMileageToolTipContent: string = "";
    voluntaryTerminationRowName: string = "";
    voluntaryTerminationToolTipContent: string = "";
    cannotCalcVTValueText: string = "";
    bereavementFileTagVTValueText: string = "";
    mileage: string = "";
    monthText: string = "";
    manageContractNote: string = "";
    duringYourContractText: string = "";
    duringContractAccordionPcp: WSSAccordion[] = [];
    duringContractAccordionHp: WSSAccordion[] = [];
    atTheEndOfContractText: string = "";
    atTheEndContractAccordionPcp: WSSAccordion[] = [];
    atTheEndContractAccordionHp: WSSAccordion[] = [];
    faqButton: string = "";
    goodConditionGuideUrlText: string = "";
    hpSchemeDescriptionText: string = "";
    leasePurchaseTxt: string = "";
}

export class UpdateContactItem {
    pageTitle: string = "";
    homeLabel: string = "";
    workLabel: string = "";
    mobileLabel: string = "";
    buttoneLabel: string = "";
    successMessage: string = "";
    successinfo: string = "";
    backToHomeLabel: string = "";
    mobileErrorMessage: string = "";
    homeErrorMessage: string = "";
    workErrorMessage: string = "";
    atleastErrorMessage: string = "";
    errorMessage: string = "";
    needHelpLink: string = "";
    contactPageWarningInfo: string = "";
    contactPageWarningInfoPartTwo: string = "";
}

export class EocVoluntaryTerminationCtaPageItem {
    pageTitleVtInProgress: string = "";
    pageTitleGetInTouch: string = "";
    nonRegulatedParagraph: string = "";
    bereavementParagraph: string = "";
    vTInProgressParagraph: string = "";
    unableToRequestPackParagraph: string = "";
    returnToDashboardText: string = "";
    returnToVTPageText: string = "";
    returnToVTPageButtonText: string = "";
    pageTitleVtOutOfHours: string = "";
    outOfHoursMainParagraphContent: string = "";
}

export class EocTrafficLightItem {
    trafficLightTitle: string = "";
    trafficLightMainParagraphDefault: string = "";
    trafficLightMainParagraphNoLiability: string = "";
    trafficLightMainParagraphPossibleLiability: string = "";
    trafficLightMainParagraphWithLiability: string = "";
    trafficLightButtonText: string = "";
    trafficLightInputPlaceholder: string = "";
    trafficLightCalculationExplaination: string = "";
}

export class VoluntaryTerminationItem {
    pageTitle: string = "";
    openingParagraph: string = "";
    canIEndEarlyHeader: string = "";
    canIEndEarlyParagraph: string = "";
    howItWorksHeader: string = "";
    howItWorksParagraph: string = "";
    whatYouNeedToKnowHeading: string = "";
    whatYouNeedToKnowAccordion: any[] = [];
    frequentlyAskedQuestionsHeader: string = "";
    frequentlyAskedQuestionsAccordion: any[] = [];
    goodsConditionGuideLinkDisplayText: string = "";
    doYouWantToVoluntaryTerminateHeading: string = "";
    noLiabilitySentece: string = "";
    withLiabilitySentece: string = "";
    manualLiabilitySentece: string = "";
    requestPackButtonText: string = "";
    getInTouch: string = "";
    modalPopupMainHeading: string = "";
    modalPopupText: string = "";
    modalPopupVtRequestSuccessHeading: string = "";
    modalPopupVtRequestSuccessParagraph: string = "";
    goBackButtonText: string = "";
    modalPopupVtRequestCloseButtonText: string = "";
    vtRequestErrorText: string = "";
    vtPackRequestFeatureFlag: string = "";
    howItWorksParagraphFlagOn: string = "";
    goingAheadVtFlagOn: string = "";
    goingAheadVtFlagOff: string = "";
}