import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '@backbase/content-ang';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable } from 'rxjs';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { appConfig } from '@wss/config/wss-app-constants';
import { Router } from '@angular/router';
import { isOutOfHours } from '@wss/common/util/util';

@Component({
  selector: 'bb-eoc-voluntary-termination-CTA-page',
  templateUrl: './eoc-voluntary-termination-CTA-page.component.html'
})

export class EocVoluntaryTerminationCtaPage implements OnInit {
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  isRegulated: boolean = false;
  vtInProgress: boolean = false;
  accountDetails: any = {};
  ctaMainParagraphContent: any;
  isBereavementFileTag: boolean = false;
  isOutOfHours: boolean = false;

  constructor(
    private datastoreService: DatastoreService,
    private bbContentService: ContentService,
    private eocService: EndOfContractService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Eoc Voluntary Termination CTA Page',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.isRegulated = this.accountDetails.regulated_ind ? true : false;
    this.isBereavementFileTag = this.accountDetails.file_tag === '13' ? true : false;
    this.isOutOfHours = isOutOfHours();
    this.vtInProgress = appConfig.vtRelatedTags.includes(this.accountDetails?.file_tag) || localStorage.getItem('vtPackAlreadyRequested') === 'yes' || this.datastoreService.getVtPackAlreadyRequested();
    this.getContentData();
  }

  getContentData() {
    this.endOfContractWidgetItem$.subscribe((pageItem: any) => {
      this.endOfContractWidgetItem = pageItem;
      this.getMainParagraphContent()
      this.changeDetectorRef.detectChanges();
    }) 
  }

  callButtonHandler(contactNumber: string) {
    window.open('tel:' + contactNumber, '_self');
  }

  navigateToVoluntaryTerminationPage() {
    this.router.navigate(['/eoc-options/voluntary-termination']);
  }

  getMainParagraphContent(){
    if(!this.isRegulated){
      this.ctaMainParagraphContent = this.endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.nonRegulatedParagraph;
    }
    else if(this.vtInProgress){
      this.ctaMainParagraphContent = this.endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.vTInProgressParagraph;
    }
    else if(this.accountDetails?.file_tag === "13"){
      this.ctaMainParagraphContent = this.endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.bereavementParagraph;
    }
    else{
      this.ctaMainParagraphContent = this.endOfContractWidgetItem.eocVoluntaryTerminationCtaPage.unableToRequestPackParagraph;
    }
  }
}