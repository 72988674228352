import { ChangeDetectorRef, Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { RouterService } from '@backbase/foundation-ang/core';
import { MessageService } from '../../../service/message.service';
import { ServiceException } from '../../../model/service-exception';
import { DatastoreService } from '../../../service/datastore.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { checkFormIsEmpty, getErrorImageClass } from '@wss/common/util/util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from '@backbase/universal-ang/content';
import { LoginService } from '@wss/login-widget/src/service/login.service';
import { StorageService } from '@wss/service/storage.service';

@Component({
  selector: 'bb-common-header-widget',
  templateUrl: './common-header.component.html',
  providers: [ContentService]
})
export class CommonHeaderWidgetComponent implements OnInit {
  // @Input() displayMode: string = '';
  @Input() headerWidgetItem: any;
  @ViewChild("content") modalContentRef: any;

  serviceException: ServiceException = new ServiceException();
  public open: boolean = false;
  personalDetails: any = {};
  customerDetail: any = {};
  brandName: string = window.BB?.config?.portalName;
  accountDetail: any = {};
  msgUnreadCount: number = 0;
  isAccountCompleted: boolean = false;
  showMailIcon: boolean = false;
  portalName: string = this.pageConfig.portalName || 'blackhorse';
  isArchive: boolean = false;
  isContactPreferenceFeatureOn: boolean = false;
  logoutPopupContent: any = {};
  modalRef: any;
  customerId: any = '';

  constructor(
    private router: RouterService,
    public changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private dataStore: DatastoreService,
    private modal: NgbModal,
    readonly bbContentService: ContentService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private loginService: LoginService, 
    private storageService: StorageService
  ) {

  }

  ngOnInit(): void {
    this.customerId = this.storageService.getLocalStorage('customerId');
    this.isAccountCompleted = this.dataStore.getIsAccountCompleted();
    this.handleProfileToggle();
    this.getCustomerDetailCall();
    this.isArchive = this.dataStore.getIsAccountArchived();
    this.showMailIcon = true;
    this.eventListner();
    this.isContactPreferenceFeatureOn = this.dataStore.getIsContactPreferenceFeature();
  }

  getCustomerDetailCall() {
    this.customerDetail = this.dataStore.getCustomerDetail();
    if (this.customerDetail) {
      let firstName = this.customerDetail?.individual?.first_name;
      let surname = this.customerDetail?.individual?.surname;
      if (firstName?.length > 18) {
        firstName = firstName.substr(0, 18);
      }
      if (surname?.length > 40) {
        surname = surname.substr(0, 40);
      }
      this.personalDetails.fullname = firstName + ' ' + surname;
      this.personalDetails.fullname = this.personalDetails.fullname.toLowerCase();
      this.personalDetails.shortName = this.customerDetail?.individual?.first_name[0] + this.customerDetail?.individual?.surname[0];
      this.accountDetail = this.dataStore.getAccountDetails();
      this.customerDetail.contact_details.email_address = this.dataStore.getLoggedInEmail();
    }
    this.changeDetectorRef.detectChanges();
  }

  logout() {
    const complaintsFormData: any = this.dataStore.getComplaintsFormData();
    if (Object.keys(complaintsFormData).length <= 2 && checkFormIsEmpty(complaintsFormData.complaintRelateToSelectionForm)) {
      this.dataStore.sessionLogout();
    } else {
      this.logoutPopupContent = this.dataStore.getComplaintsGetStartedContent();
      this.modalRef = this.modal.open(this.modalContentRef, {
        backdrop: "static",
        centered: true
      });
    }
    this.toggle(true);
  }

  leave() {
    this.dataStore.sessionLogout();
  }

  eventListner(): void {
    window.addEventListener('click', (env: any) => {
      var element = env.target;
      if (element.closest('#commonheaderPopupContent') && element.closest('#editIcon') && element.closest('#editIcon').length === 0) {
        this.toggle(false);
      }
      else if (element.id == 'pop_show' || element.id == 'checkout' || element.id == 'popup-show') {
      } else {
        this.toggle(true);
        this.changeDetectorRef.markForCheck();
      }
    });
    this.getMailboxUnreadCount();
  }

  /*checjk*/
  navigatePage(uri: string) {
    this.toggle(true);
    let currentTimeHours = new Date().getHours();
    if ((currentTimeHours < 22 && currentTimeHours >= 8)
      || (uri.indexOf("change-password") !== -1)
      || (uri.indexOf("manage-preference") !== -1)
      || (uri.indexOf("update-email") !== -1)
      || (uri.indexOf("contact-preference") !== -1)) {
      // if (currentTimeHours < 22 && currentTimeHours >= 8) {
      if (uri.indexOf("manage-preference") !== -1) {
        this.dataStore.setPreferenceLaunchThrough("profile");
      }
      this.router.navigate([uri]);
      this.messageService.getNavigationChange(true);
    } else {
      this.showOutOfWorkHoursError();
    }
  }

  toggle(status: boolean) {
    this.open = !status;
    this.changeDetectorRef.detectChanges();
  }

  showOutOfWorkHoursError() {
    this.serviceException.imageClass = getErrorImageClass(this.headerWidgetItem?.userDetailsprofile.timeoutException.errorImageType.toString());
    this.serviceException.errorInfoText = this.headerWidgetItem?.userDetailsprofile.timeoutException.errorInfoText;
    this.serviceException.buttonText = this.headerWidgetItem?.userDetailsprofile.timeoutException.buttonText;
    this.serviceException.componentUri = this.headerWidgetItem?.userDetailsprofile.timeoutException.componentUri;
    this.serviceException.cancelLink = this.headerWidgetItem?.userDetailsprofile.timeoutException.cancelLink;
    this.messageService.setServiceException(this.serviceException);
    this.router.navigate(['/payments/service-exception']);

  }

  handleProfileToggle() {
    this.dataStore.customerDetailResponse.subscribe(response => {
      if (response) {
        this.getCustomerDetailCall();
      }
    });
    this.messageService.getProfileActive().subscribe((isOpen) => {
      this.toggle(this.open);
    });
  }

  toInboxMsg() {
    this.router.navigate(['/mailbox']);
  }

  getMailboxUnreadCount() {
    this.dataStore.getMailboxUnreadCount().subscribe(count => {
      this.msgUnreadCount = count;
      this.changeDetectorRef.detectChanges();
    });
  }
}
