<ng-container *ngIf="endOfContractWidgetItem">
  <div role="alert" *ngIf="vTRequestError">
    <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
  </div>
  <div class="mb-10px margin-top-10 help-centre-widget-container" *ngIf="endOfContractWidgetItem.voluntaryTermination">
    <div class="eoc-vt-header">
      <span class="section-label-title" test_id="pageTitle">
        {{ endOfContractWidgetItem.voluntaryTermination.pageTitle}}
      </span>
    </div>
    <div class="margin-top-10">
      <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.openingParagraph"></bb-content-html>
    </div>

    <div class="card mt-0 mb-0">
      <div class="card-content">
        <h3 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
          {{ endOfContractWidgetItem.voluntaryTermination.canIEndEarlyHeader }}
        </h3>
        <div class="margin-top-10">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.canIEndEarlyParagraph">
          </bb-content-html>
        </div>
      </div>
    </div>

    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.howItWorksHeader }}
    </h2>
    <div class="margin-top-10">
      <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.howItWorksParagraph"></bb-content-html>
    </div>
    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.whatYouNeedToKnowHeading }}
    </h2>
    <div class="accordion margin-top-10">
      <bb-common-expansion-panel
        [accordionContent]="endOfContractWidgetItem.voluntaryTermination.whatYouNeedToKnowAccordion">
      </bb-common-expansion-panel>
    </div>
    <h2 class="font-size-20 mb-15px margin-top-10" test_id="voluntaryTerminationWhatIsVTTitle">
      {{ endOfContractWidgetItem.voluntaryTermination.frequentlyAskedQuestionsHeader }}
    </h2>
    <div class="accordion margin-top-10">
      <bb-common-expansion-panel
        [accordionContent]="endOfContractWidgetItem.voluntaryTermination.frequentlyAskedQuestionsAccordion">
      </bb-common-expansion-panel>
    </div>
 
    <div class="card" id="vtPackRequestCard" *ngIf="displayVTrequest">
      <div class="card-content text-center">
        <div class="p-mb-10px" test_id="wantToVoluntaryTerminate">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.doYouWantToVoluntaryTerminateHeading">
          </bb-content-html>
        </div>
        <div class="p-mb-10px">
          <bb-content-html [html]="this.liabilityStatusContent">
          </bb-content-html>
        </div>
        <div class="d-flex justify-content-center">
          <button [disabled]="isCompleted" class="wss-button" (click)="openModal(vtRequestModal)" test_id="requestVoluntaryTerminationPack">
            {{ endOfContractWidgetItem.voluntaryTermination.requestPackButtonText }}
          </button>
        </div>
      </div>
      <div class="card-footer" test_id="haveQuestionsText">
        <span class="margin-top-10 text-center">
          <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.getInTouch">
          </bb-content-html>
        </span>
      </div>
    </div>

    <bb-eoc-traffic-light></bb-eoc-traffic-light>

    <ng-template #vtRequestModal let-modal>
      <div class="container-column request-vt-pack-modal voluntary-termination-popup-container">
          <div class="container-row modal-header-c" test_id="vtPopUpPrimaryHeadingTestId">
            <span class="popup-header">
              <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupMainHeading">
              </bb-content-html>
            </span>
            <span class="material-icons" (click)="closeModal();modal.dismiss('Cross click');">clear</span>
          </div>
          <div class="container-column modal-body-c" test_id="vtPopUpMainTextTestId" id="vtPopUpMainText">
            <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupText">
            </bb-content-html>
          </div>
          <div class="container-column modal-footer-c space-between" test_id="requestVoluntaryTerminationPackTestId" id="requestVoluntaryTerminationPack">
            <button [disabled]="isCompleted" class="wss-button mb-0" (click)="requestVoluntaryTerminationPack()">
              {{ endOfContractWidgetItem.voluntaryTermination.requestPackButtonText }}
            </button>
            <button class="wss-button-secondary btn-dif-view take-me-back" id="voluntaryTerminationPopUpGoBackButton"
              (click)="closeModal();modal.dismiss('Cross click');">
              {{ endOfContractWidgetItem.voluntaryTermination.goBackButtonText }}
            </button>
          </div>
        </div>
    </ng-template>

    <ng-template #vtRequestSuccessfulModal let-modal>
      <div class="container-column text-center request-vt-pack-modal send-settlement-email">
        <div>
          <div class="container-row modal-header-c" test_id="crossClick">
              <span class="wss-success-icon success-image" alt="Success Icon"></span>
              <span class="material-icons" (click)="closeModal();modal.dismiss('Cross click');">clear</span>
          </div>
          <span class="popup-header mb-0">
            <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupVtRequestSuccessHeading">
            </bb-content-html>
          </span>
          <div class="container-column modal-body-c" test_id="vtRequestSuccessfulModalParagraphTestId" id="vtRequestSuccessfulModalParagraph">
            <bb-content-html [html]="endOfContractWidgetItem.voluntaryTermination.modalPopupVtRequestSuccessParagraph">
            </bb-content-html>
          </div>
          <div class="container-row modal-footer-c close-window" test_id="closeThisWindowButton">
            <button class="wss-button" id="voluntaryTerminationPopUpGoBackButton"
            (click)="closeModal();modal.dismiss('Cross click');">
            {{ endOfContractWidgetItem.voluntaryTermination.modalPopupVtRequestCloseButtonText }}
          </button>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</ng-container>
