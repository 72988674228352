
import { BrandContactDetailsItem } from "./common/brand-contant-details";
export class HandBackChecklistWidgetItem {
    // handbackChecklistDetails: HandBackChecklistWidgetData = new HandBackChecklistWidgetData();
    handbackChecklistDetails = new HandBackChecklistWidgetData();
    brandContactDetails = new BrandContactDetailsItem();
    eocGoodConditionGuide = new GoodConditionGuideItem();
}

export class HandBackChecklistWidgetData {
    pageName: string = '';
    textBelowPageHeader: string = '';
    fewWeeksBeforeText: string = '';
    fewWeeksBeforeFirstPointHeader: string = '';
    fewWeeksBeforeFirstPointContentBeforeData: string = '';
    goodConditionguideLink: string = '';
    fewWeeksBeforeFirstPointContentAfterData: string = '';
    fewWeeksBeforeSecondPointContent: string = '';
    fewWeeksBeforeThirdPointContent: string = '';
    fewWeeksBeforeFourthPointContent: string = '';
    fewWeeksBeforeFifthPointContent: string = '';
    onTheDayOfCollectionText: string = '';
    onTheDayOfCollectionFirstPointContent: string = '';
    onTheDayOfCollectionSecondPointContent: string = '';
    onTheDayOfCollectionThirdPointContentBeforeData: string = '';
    onTheDayOfCollectionThirdPointContentAfterData: string = '';
    onTheDayOfCollectionFourthPointContent: string = '';
    onTheDayOfCollectionFifthPointContent: string = '';
    onTheDayOfCollectionSixthPointContent: string = '';
    onTheDayOfCollectionSeventhPointContent: string = '';
}

export class GoodConditionGuideItem {
    blackHorseAllTypesMotorVehicle: string = "";
    blackhorseBikeScooter: string = "";
    blackhorseMotorhome: string = "";
    blackhorseTouringCarvan: string = "";
    blackhorseTaxi: string = "";
    blackhorseOffshoreScooter: string = "";
    blackhorseOffshoreBikeScooter: string = "";
    jaguar: string = "";
    landRover: string = "";
    mitsubishi: string = "";
    halifax: string = "";
    lloyds: string = "";
    bankOfScotland: string = "";
    suzukiCar: string = "";
    suzukiBike: string = "";
    imf: string = "";
}
