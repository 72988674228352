import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from '@backbase/content-ang';
import { EndOfContractItem } from '@wss/model/end-of-contract/endOfContract';
import { Observable, Subject } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatastoreService } from '@wss/service/datastore.service';
import { EndOfContractService } from '../../services/end-of-contract.service';
import { cleanCurrency, injectGoodConditionGuideIntoPageWithDisplayText, injectVTContentIntoPage, isErrServer, isNotEmpOrNullOrUndefOrWhiteSpace, isOutOfHours } from '@wss/common/util/util';
import { Router } from '@angular/router';
import { appConfig, vtMemoText } from '@wss/config/wss-app-constants';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'bb-voluntary-termination',
  templateUrl: './voluntary-termination.component.html'
})

export class VoluntaryTermination implements OnInit {
  @ViewChild('vtRequestSuccessfulModal') vtRequestSuccessfulModal!: TemplateRef<any>;
  endOfContractWidgetItem: EndOfContractItem = new EndOfContractItem();
  endOfContractWidgetItem$: Observable<EndOfContractItem | undefined> = this.bbContentService.getContent<EndOfContractItem>('endOfContractWidgetItem');
  modalRef: any;
  accountDetails: any;
  dealerDetails: any;
  linkRoute: string = '#';
  portalName: string = window.BB?.config?.portalName ? window.BB?.config.portalName : '';
  liabilityStatusContent: string = "";
  liabilityValue: number = 0;
  isLiabilityValueError: boolean = false;
  canVtBeCalculated: boolean = false;
  isOutOfHours: boolean = false;
  vTRequestError: boolean = false;
  isCompleted: boolean = false;
  vtPackRequested: string = localStorage.getItem('vtPackAlreadyRequested') || '';
  errorCode: any = '';
  readonly destroy$ = new Subject();
  messageService: any;
  displayVTrequest: boolean = true;

  
  constructor(
    private bbContentService: ContentService,
    private tealiumService: TealiumUtagService,
    public changeDetectorRef: ChangeDetectorRef,
    public datastoreService: DatastoreService,
    private modalService: NgbModal,
    private eocService: EndOfContractService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tealiumService.view(
      {
        JourneyName: 'End of Contract',
        JourneyStepName: 'Voluntary Termination',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );

    this.accountDetails = this.datastoreService.getAccountDetails();
    this.dealerDetails = this.datastoreService.getDealersDetails();
    this.canVtBeCalculated = this.accountDetails?.voluntary_terminated_flag;
    this.isCompleted = this.accountDetails.completion_date ? true : false;

    if (!this.dealerDetails?.dlr_type) {
      this.getDealersDetails();
    }
    if(isNotEmpOrNullOrUndefOrWhiteSpace(this.accountDetails?.voluntary_termination_amount)){
      this.liabilityValue = cleanCurrency(this.accountDetails?.voluntary_termination_amount);
    }
    else{
      this.isLiabilityValueError = true;
    }
    localStorage.removeItem('voluntaryTerminationRedirection');
    this.getContentData();
  }
  
  getContentData() {
    this.endOfContractWidgetItem$.subscribe((pageItem: any) => {
      this.endOfContractWidgetItem = pageItem;
      this.setDisplayVTrequest();
      this.createLiabilityStatusContent();
      let linkDisplaytext = this.endOfContractWidgetItem.voluntaryTermination.goodsConditionGuideLinkDisplayText;
      this.linkRoute = this.eocService.getGoodConditionLink(this.portalName, this.dealerDetails?.dlr_type, this.endOfContractWidgetItem?.eocGoodConditionGuide, this.accountDetails.goods_code);
      this.endOfContractWidgetItem.voluntaryTermination = injectGoodConditionGuideIntoPageWithDisplayText(this.endOfContractWidgetItem.voluntaryTermination, this.linkRoute, linkDisplaytext);
      this.endOfContractWidgetItem.voluntaryTermination = injectVTContentIntoPage(this.endOfContractWidgetItem.voluntaryTermination, this.displayVTrequest);
      this.changeDetectorRef.detectChanges();
    }) 
  }
  
  openModal(content: any) {
    if(this.notAllowedToRequestVtPack()){
      this.navigateToVtCtaPage();
    }
    else{
      this.modalRef =  this.modalService.open(content);
    }
  }

  closeModal(){
    if (this.modalRef) {
        this.modalRef.close();
    }
  }

  requestVoluntaryTerminationPack(){
    if(this.notAllowedToRequestVtPack()){
      this.closeModal();
      this.navigateToVtCtaPage();
    } else {
      this.eocService.setVoluntaryTerminationRTF()
        .subscribe((res) => {
          this.postCurmVT();
          this.closeModal();
          this.openModal(this.vtRequestSuccessfulModal);
          this.setVTRequested();
        }, (error) => {
          this.handleError(error)
        })
    }   
  }

  handleError(error: any){
    this.vTRequestError = true;
    this.closeModal();

    if (error.status === 400) {
      const errorCodes = error?.error?.map((e: any) => {
        return e.code
      })
      this.errorCode = errorCodes.join(', ');
      this.datastoreService.setErrorScreen(this.endOfContractWidgetItem.voluntaryTermination.vtRequestErrorText);
    } else {
      this.datastoreService.setErrorServer();
    }
    this.scrollToErrorMessage();
    this.changeDetectorRef.detectChanges();
  }

  postCurmVT() {
    const payload = {
      comments: [
        { Remark: this.accountDetails.account_number + " " + vtMemoText.webservRequest },
        { Remark: vtMemoText.vtPackRequested},
        { Remark: vtMemoText.date + ': ' + moment().format('DD/MM/YYYY HH:mm:ss') }
      ]
    }
      this.eocService.postCustomerComments(payload).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      }, 
      (error: any) => {
      });
  }

  navigateToVtCtaPage(){
    this.router.navigate(['/eoc-options/cta-voluntary-termination']);
  }
  
  scrollToErrorMessage() {
    document.getElementsByClassName('common-header')[0]?.scrollIntoView({ behavior: 'smooth' })
  }

  getDealersDetails() {
    this.eocService.getDealersDetails(this.accountDetails?.dealer_number)
      .subscribe((res: any) => {
        this.dealerDetails = res;
        this.changeDetectorRef.detectChanges();
      }, (error: any) => {})
  }

  createLiabilityStatusContent(){
    if(!this.isLiabilityValueError && this.canVtBeCalculated === true && this.liabilityValue === 0){
      this.liabilityStatusContent = this.endOfContractWidgetItem.voluntaryTermination.noLiabilitySentece;
    }
    else if(!this.isLiabilityValueError && this.canVtBeCalculated === true && this.liabilityValue > 0 ){
      let tempStatement = this.endOfContractWidgetItem.voluntaryTermination.withLiabilitySentece;
      let tempLiabilityFigure = this.liabilityValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      tempStatement = tempStatement.replace("{{liabilityFigure}}", tempLiabilityFigure);
      this.liabilityStatusContent = tempStatement;
    }
    else{
      this.liabilityStatusContent = this.endOfContractWidgetItem.voluntaryTermination.manualLiabilitySentece;
    }
  }
  
  setVTRequested() {
    localStorage.setItem('vtPackAlreadyRequested', 'yes');
    this.datastoreService.setVtPackAlreadyRequested();
  }

  isVtPackAlreadyRequested() {
    return localStorage.getItem('vtPackAlreadyRequested') === 'yes' || this.vtPackRequested === 'yes' || this.datastoreService.getVtPackAlreadyRequested();
  }

  notAllowedToRequestVtPack(): boolean{
    return (( this.accountDetails.file_tag && this.accountDetails.file_tag !== "00" ) || isOutOfHours() || this.isVtPackAlreadyRequested() || this.isCompleted )
  }

  setDisplayVTrequest() {
    this.displayVTrequest = this.endOfContractWidgetItem.voluntaryTermination.vtPackRequestFeatureFlag === "false" ? false : true;
  }
}