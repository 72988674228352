<div *ngIf="complaintWidgetItem">
  <form [formGroup]="complaintAboutYourVehicleSelectionForm" formInvlidControlScroll>
    <div class="container-column">
      <div class="help-centre-title">
        <span class="section-label-title" test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintAboutYourVehicle.pageTitle}}</span>
      </div>

      <div class="sub-heading mt-30px" test_id="pageHeaderBelowText">
        <bb-content-html [html]="complaintWidgetItem.complaintAboutYourVehicle.pageHeaderBelowText"></bb-content-html>
      </div>
      <div class="form-group mt-10px">
        <div test_id="anyIssuesDiagnosedLabel">
          <strong>{{complaintWidgetItem.complaintAboutYourVehicle.anyIssuesDiagnosedLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="anyIssuesDiagnosedOption" value="Yes"
              id="anyIssuesDiagnosedYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').errors && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').invalid && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').errors.required}">
            <span class="label" test_id="yesSelection">{{complaintWidgetItem.complaintAboutYourVehicle.yesText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30"
          *ngIf="any_issues_diagnosed_selection_value?.value == 'Yes'">
          <label>{{complaintWidgetItem.complaintAboutYourVehicle.whatIssuesDiagnosedLabel}}</label>
          <div class="mb-10px">
            <span class="mb-10px">{{3000-textAreaOneRemainingCharacter}}
              {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
          </div>
          <textarea
            [ngClass]="{'has-Error': submitted && complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').errors && (complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').invalid || complaintAboutYourVehicleSelectionForm.get('whatAndWhenIssueDiagnosed').errors.required)}"
            class="form-control" id="whatIssuesDiagnosedTextarea" formControlName="whatAndWhenIssueDiagnosed" rows="3"
            maxlength="3000"></textarea>
          <div
            *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.whatAndWhenIssueDiagnosed.errors?.required">
            <small class="text-danger" test_id="whatAndWhenIssueDiagnosed">
              {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
            </small>
          </div>
          <div *ngIf="invalidIssueDiagnosed">
            <small class="text-danger" test_id="whatAndWhenIssueDiagnosed">
              {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
            </small>
          </div>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="anyIssuesDiagnosedOption" value="No"
              id="anyIssuesDiagnosedNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').errors && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').invalid && complaintAboutYourVehicleSelectionForm.get('anyIssuesDiagnosedOption').errors.required}"><span
              class="label" test_id="NoSelection">{{complaintWidgetItem.complaintRelateTo.noText}}</span>
          </label>
        </div>
        <div
          *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.anyIssuesDiagnosedOption.errors?.required">
          <small class="text-danger" test_id="anyIssuesDiagnosedOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px">
        <div test_id="anyGarageOtherThanSupplyingLabel">
          <strong>{{complaintWidgetItem.complaintAboutYourVehicle.anyGarageOtherThanSupplyingLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="anyGarageOtherThanSupplyingOption" value="Yes"
              id="anyGarageOtherThanDealerYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').errors && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').invalid && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').errors.required}">
            <span class="label"
              test_id="anyGarageOtherThanSupplyingYesSelection">{{complaintWidgetItem.complaintAboutYourVehicle.yesText}}</span>
          </label>
        </div>
        <div class="form-group form-group-textarea mt-10px ps-30"
          *ngIf="any_garage_other_than_Supplying_selection_value?.value == 'Yes'">
          <label>{{complaintWidgetItem.complaintAboutYourVehicle.authoriseToSpeakToGarageText}}</label>
          <div class="radio mt-10px">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" formControlName="authoriseToSpeakToGarage" value="Yes"
                id="authoriseToSpeakToGarageYesOption"
                [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').errors && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').invalid && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').errors.required}">
              <span class="label"
                test_id="authoriseToSpeakToGarageYesSelection">{{complaintWidgetItem.complaintAboutYourVehicle.yesText}}</span>
            </label>
          </div>
          <div class="form-group form-group-textarea mt-10px ps-30"
            *ngIf="authoriseToSpeakToGarageFormValue?.value == 'Yes'">
            <label>{{complaintWidgetItem.complaintAboutYourVehicle.provideGarageContactDetailLabel}}</label>
            <div class="mb-10px">
              <span class="mb-10px">{{250-textAreaTwoRemainingCharacter}}
                {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
            </div>
            <textarea
              [ngClass]="{'has-Error': submitted && complaintAboutYourVehicleSelectionForm.get('garageContactDetails').errors && (complaintAboutYourVehicleSelectionForm.get('garageContactDetails').invalid || complaintAboutYourVehicleSelectionForm.get('garageContactDetails').errors.required)}"
              class="form-control" id="provideGarageContactDetailTextarea" formControlName="garageContactDetails"
              rows="3" maxlength="250"></textarea>
            <div
              *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.garageContactDetails.errors?.required">
              <small class="text-danger" test_id="provideGarageContactDetailTextarea">
                {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
              </small>
            </div>
            <div *ngIf="invalidGarageContactDetails">
              <small class="text-danger" test_id="garageContactDetails">
                {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
              </small>
            </div>
          </div>
          <div class="radio mt-10px">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" formControlName="authoriseToSpeakToGarage" value="No"
                id="authoriseToSpeakToGarageNoOption"
                [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').errors && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').invalid && complaintAboutYourVehicleSelectionForm.get('authoriseToSpeakToGarage').errors.required}">
              <span class="label"
                test_id="authoriseToSpeakToGarageNoSelection">{{complaintWidgetItem.complaintAboutYourVehicle.noText}}</span>
            </label>
          </div>
          <div
            *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.authoriseToSpeakToGarage.errors?.required">
            <small class="text-danger" test_id="authoriseToSpeakToGarageOptionError">
              {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
            </small>
          </div>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="anyGarageOtherThanSupplyingOption" value="No"
              id="anyGarageOtherThanDealerNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').errors && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').invalid && complaintAboutYourVehicleSelectionForm.get('anyGarageOtherThanSupplyingOption').errors.required}">
            <span class="label"
              test_id="anyGarageOtherThanSupplyingNoSelection">{{complaintWidgetItem.complaintRelateTo.noText}}</span>
          </label>
        </div>
        <div
          *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.anyGarageOtherThanSupplyingOption.errors?.required">
          <small class="text-danger" test_id="anyGarageOtherThanSupplyingOptionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px" *ngIf="inGoodsScope">
        <label>{{complaintWidgetItem.complaintAboutYourVehicle.whatCurrentMileageLabel}}</label>
        <div class="mb-10px">
          <span class="mb-10px">{{6-mileageRemainingNumerals}}
            {{complaintWidgetItem.complaintAboutYourVehicle.numeralsRemainingLabel}}</span>
        </div>
        <input type="tel" class="form-control form-input" test_id="mileageInput" formControlName="mileage"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57;" maxlength="6"
          [ngClass]="{'has-Error': submitted && complaintAboutYourVehicleSelectionForm.get('mileage').errors && (complaintAboutYourVehicleSelectionForm.get('mileage').invalid || complaintAboutYourVehicleSelectionForm.get('mileage').errors.required)}">
        <div *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.mileage.errors?.required">
          <small class="text-danger" test_id="mileageError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
          </small>
        </div>
      </div>

      <div class="form-group form-group-textarea mt-20px">
        <label>{{complaintWidgetItem.complaintAboutYourVehicle.whereVehicleLocatedLabel}}</label>
        <div class="mb-10px">
          <span class="mb-10px">{{250-textAreaThreeRemainingCharacter}}
            {{complaintWidgetItem.complaintTellUs.characterReaminingLabel}}</span>
        </div>
        <textarea formControlName="whereVehicleLocatedTextarea" class="form-control" id="whereVehicleLocatedTextarea"
          rows="3" maxlength="250"
          [ngClass]="{'has-Error': submitted && complaintAboutYourVehicleSelectionForm.get('whereVehicleLocatedTextarea').errors && (complaintAboutYourVehicleSelectionForm.get('whereVehicleLocatedTextarea').invalid || complaintAboutYourVehicleSelectionForm.get('whereVehicleLocatedTextarea').errors.required)}"></textarea>
        <div
          *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.whereVehicleLocatedTextarea.errors?.required">
          <small class="text-danger" test_id="mileageError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseEnterDetailsErrorMessage }}
          </small>
        </div>
        <div *ngIf="invalidVehicleLocated">
          <small class="text-danger" test_id="contactDetail">
            {{complaintWidgetItem.complaintRelateTo.specialCharactersAllowedText}}
          </small>
        </div>
      </div>

      <div class="form-group mt-20px">
        <div test_id="vehicleDriveableOrSafeLabel">
          <strong>{{complaintWidgetItem.complaintAboutYourVehicle.vehicleDriveableOrSafeLabel}}</strong>
        </div>

        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="vehicleDriveableOrSafeSelection" value="Yes"
              id="vehicleSafeToDriveYesOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').errors && (complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').invalid || complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').errors.required)}">
            <span class="label"
              test_id="vehicleDriveableOrSafeYesSelection">{{complaintWidgetItem.complaintAboutYourVehicle.yesText}}</span>
          </label>
        </div>
        <div class="radio mt-10px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="vehicleDriveableOrSafeSelection" value="No"
              id="vehicleSafeToDriveNoOption"
              [ngClass]="{'has-Error-Radio': submitted && complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').errors && (complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').invalid || complaintAboutYourVehicleSelectionForm.get('vehicleDriveableOrSafeSelection').errors.required)}">
            <span class="label"
              test_id="vehicleDriveableOrSafeNoSelection">{{complaintWidgetItem.complaintAboutYourVehicle.noText}}</span>
          </label>
        </div>
        <div
          *ngIf="submitted && complaintAboutYourVehicleSelectionFormControl.vehicleDriveableOrSafeSelection.errors?.required">
          <small class="text-danger" test_id="vehicleDriveableOrSafeSelectionError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>

      <div class="complaints-btn-group margin-top-20">
        <button class="wss-button-secondary" test_id="previousButton" id="aboutVehiclePreviousButton"
          (click)="handlePreviousBtn()">
          {{complaintWidgetItem.complaintRelateTo.secondaryButtonText}}
        </button>
        <button class="wss-button" test_id="nextButton" id="aboutVehicleNextButton"
          (click)="handleNextBtn()">{{complaintWidgetItem.complaintRelateTo.primaryButtonText}}</button>
      </div>
    </div>
  </form>
</div>