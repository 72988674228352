import { Component, OnInit, ChangeDetectorRef, Inject, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserSearchService } from '../../service/user-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from '../../user';
import { UserRepoService } from '../../service/user-repo.service';
import { appConfig, RuntimeEnv } from '@wss/config/wss-app-constants';
import { ContentService } from '@backbase/universal-ang/content';
import { LoginService } from '../../service/login.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { AccountList } from '@wss/model/accountList';
import { BannerMessage } from '@wss/model/bannerMessage';
import { MessageService } from '@wss/service/message.service';
import { LoginWidgetItem } from '@wss/model/login-widget/login-widget';
import { isErrServer, isNotEmpOrNullOrUndef } from '@wss/common/util/util';
import { HttpService } from 'libs/service/http.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { finalize, takeUntil } from 'rxjs/operators';
import { TealiumUtagService } from '@wss/service/utag.service';
import { RegisterDatastoreService } from '@wss/registration-widget/src/service/register-datastore.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { DataService } from '@wss/complaints-widget/src/services/data.service';

declare let document: any;

@Component({
  selector: 'bb-wss-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  providers: [ContentService],
})
export class UserLoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;
  loginAttempt = 0;
  validEmailFormat = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i; //NOSONAR
  validEmail = false;
  validPwd = false;

  users$: Observable<User[]> | undefined;
  private userSearchService: UserSearchService;
  private loginService: LoginService;
  private nav: Router;
  submitted = false;
  failed = false;
  bannerMessage: BannerMessage = new BannerMessage();
  errorDetails: boolean = false;

  accountList: AccountList[] = [];
  accountCount: number = 0;
  authorisation: any;
  showEmailErrors: boolean = false;

  upperCaseLetterformat = /[A-Z]+/;
  lowerCaseLetterformat = /[a-z]+/;
  numberformat = /[0-9]+/;
  showPasswordError: boolean = false;
  serviceError: boolean = false;

  onOffSwitchObject: Object = {
    digi: {
      blackhorse: true,
      halifax: true,
      bankofscotland: true,
      lloyds: true,
      jaguar: true,
      landrover: true,
      suzukifinance: true,
      internationalmotors: true,
      mitsubishishogun: true,
    },
    eoc: {
      blackhorse: true,
      halifax: true,
      bankofscotland: true,
      lloyds: true,
      jaguar: true,
      landrover: true,
      suzukifinance: true,
      internationalmotors: true,
      mitsubishishogun: true,
    },
    cfa: {
      blackhorse: true,
      halifax: true,
      bankofscotland: true,
      lloyds: true,
      jaguar: true,
      landrover: true,
      suzukifinance: true,
      internationalmotors: true,
      mitsubishishogun: true,
    },
  };

  portalName: string = this.pageConfig.portalName || 'blackhorse';
  loginWidgetItem$: Observable<LoginWidgetItem | undefined> = this.bbContentService.getContent<LoginWidgetItem>(
    'loginWidgetItem',
  );
  redirectToSettlement: boolean = false;
  showEmailInfoPopup: boolean = false;
  readonly destroy$ = new Subject();
  errorCode: any;
  encryptedEmail: any;

  constructor(
    private formBuilder: FormBuilder,
    userSearchService: UserSearchService,
    loginService: LoginService,
    routerAng: Router,
    private datastoreService: DatastoreService,
    private userRepoService: UserRepoService,
    public changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private readonly bbContentService: ContentService,
    private httpService: HttpService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService,
    private routerAct: ActivatedRoute,
    private renderer: Renderer2,
    private registerDatastoreService: RegisterDatastoreService,
    private bnIdle: BnNgIdleService,
    private formDataService: DataService,
  ) {
    this.userSearchService = userSearchService;
    this.loginService = loginService;
    this.nav = routerAng;
    this.userRepoService.updateScreenTitle('Create An Account');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.validEmailFormat)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {
    this.clearPrevSession();
    this.tealiumService.view(
      {
        JourneyName: 'Login',
        JourneyStepName: 'User Login',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.routerAct.queryParams.subscribe(param => {
      if (param['email']) {
        this.encryptedEmail = param['email'];
      }
    });
    if (this.registerDatastoreService.getUserAlias() != null) {
      this.loginForm.controls['email'].patchValue(this.registerDatastoreService.getUserAlias());
    }
    this.loginEmailEnter();
    this.loginPwdEnter();
    this.setErrorMsg();
    this.bnIdle.startWatching(appConfig.appTimeout).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.getAuthorised();
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  clearPrevSession() {
    if (RuntimeEnv.backbase) {
      if (!this.pageConfig.designmode) {
        this.httpService.getLogout().subscribe((data: any) => {
          this.datastoreService.sessionLogin();
        }).add(() => {
          this.getAuthorised();
        });
      }
    }
  }

  setErrorMsg() {
    this.bannerMessage.closeIconClass = 'clear';
    this.bannerMessage.infoTypeClass = 'icon-warning-red wss-additonal-info-icon additonalerror-info-icon mgn-top-15px';
    this.bannerMessage.leftSectionColorClass = 'message-failure';
    this.bannerMessage.titleText =
      "Sorry, we can't find an account with that email address and password. Check the details you entered below and try again.";
    this.bannerMessage.isBannerClosed = false;
    this.messageService.setBannerMessage(this.bannerMessage);
  }

  setHeaders(token: any): void {
    sessionStorage.setItem('tokenReq', token);
  }

  /** Transforming  data for simplified FE development
   */
  creatOnOffObject(result: any) {
    const brandObject: String[] = appConfig.brandObjectOnOff;

    let tempObject: any = {
      digi: {},
      eoc: {},
      cfa: {},
    };
    Object.entries(result.propertySources[0].source).forEach(([key, value]) => {
      if (key.includes('mfvs.feature.digi-inbox') && brandObject.includes(this.searchBrandName(key))) {
        tempObject.digi[this.searchBrandName(key)] = value;
      } else if (key.includes('mfvs.feature.end-of-contract') && brandObject.includes(this.searchBrandName(key))) {
        tempObject.eoc[this.searchBrandName(key)] = value;
      } else if (key.includes('mfvs.feature.cfa') && brandObject.includes(this.searchBrandName(key))) {
        tempObject.cfa[this.searchBrandName(key)] = value;
      }
    });
    return tempObject;
  }

  searchBrandName(key: String) {
    const val = key.split('.');
    return val[3];
  }

  navigateAfterLogin(loginResponse: any) {
    let brandCompanyNumbers: any = [];
    appConfig.brandCompanyDetails.forEach((element: any) => {
      if (element.brandName == this.portalName) {
        brandCompanyNumbers = element.companyNumber;
      }
    });

    let updatedAccountsList: any = [];
    loginResponse.accounts.forEach((accList: any) => {
      if (brandCompanyNumbers.indexOf(accList.company_number) !== -1) {
        updatedAccountsList.push(accList);
      }
    });

    this.datastoreService.setAccountList(updatedAccountsList);
    this.datastoreService.setAccountCount(updatedAccountsList.length);
    this.accountList = this.datastoreService.getAccountList();
    this.accountCount = this.datastoreService.getAccountCount();
    sessionStorage.removeItem('failCount');
    this.datastoreService.updateBureauCallFailCount(0);
    this.datastoreService.setBureauCallAction('');
    if (this.accountCount > 1) {
      localStorage.setItem('loggedIn', 'true');
      this.nav.navigate(['multiple-accounts']);
    } else {
      let accountNo = updatedAccountsList[0].account_number;
      this.datastoreService.setAccountNo(accountNo);
      let account = this.accountList.filter(account => account.account_number === accountNo)[0];
      localStorage.setItem('loggedIn', 'true');

      if (isNotEmpOrNullOrUndef(account.completion_date)) {
        this.datastoreService.setIsAccountCompleted();
      }

      if (isNotEmpOrNullOrUndef(account.archive_date)) {
        this.datastoreService.setIsAccountArchived(true);
        location.href = appConfig.mailboxURL;
      }
      else {
        location.href = appConfig.dashboardURL;
      }
    }
    let concernType: any = {}
    this.routerAct.queryParams.subscribe(params => {
      let urlQueryParam = '';
      if (params['redirectTo']) {
        urlQueryParam = atob(decodeURIComponent(params['redirectTo'])) ? atob(decodeURIComponent(params['redirectTo'])) : params['redirectTo'];
      }
      if (urlQueryParam === 'settlement') {
        localStorage.setItem('redirectionFlag', 'settlement');
        this.redirectToSettlement = true;
        document.getElementById("appLoader").style.display = "block";
        document.querySelector("body").classList.add("block-ui");
        localStorage.setItem('settlementRedirectionFlag', 'true');
      } else if (urlQueryParam === 'makePaymentFAQs') {
        localStorage.setItem('makePaymentFAQsRedirection', 'true');
      }
      else if (urlQueryParam === 'getInTouch') {
        localStorage.setItem('getInTouchRedirection', 'true');
      }
      else if (urlQueryParam == 'voluntary-termination') {
        localStorage.setItem('voluntaryTerminationRedirection', 'true');
      }
      else if ((urlQueryParam === 'complaints' && atob(decodeURIComponent(params['complaintsJourney'])) === 'Vehicle')
        || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Vehicle')) {
        concernType = { concernType: 'Vehicle' };
        localStorage.setItem('complaintsRedirection', 'true');
        this.formDataService.saveFormData('concernSelectionForm', concernType);
      } else if ((urlQueryParam === 'complaints' && atob(decodeURIComponent(params['complaintsJourney'])) === 'Others')
        || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Others')) {
        concernType = { concernType: 'FinanceAgreement' };
        localStorage.setItem('complaintsRedirection', 'true');
        this.formDataService.saveFormData('concernSelectionForm', concernType);
      }
      else {
        this.redirectToSettlement = false;
        localStorage.setItem('settlementRedirectionFlag', 'false');
        localStorage.setItem('makePaymentFAQsRedirection', 'false');
        localStorage.setItem('getInTouchRedirection', 'false');
        localStorage.setItem('complaintsRedirection', 'false');
        localStorage.setItem('voluntaryTerminationRedirection', 'false');
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  checkValidEmail(email: any) {
    if (email && this.validEmailFormat.test(email)) {
      this.validEmail = true;
      this.showEmailErrors = false;
    } else {
      this.validEmail = false;
      this.showEmailErrors = true;
    }
    if (email.length == 0) {
      this.showEmailErrors = false;
    }
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  loginEmailEnter() {
    const email = this.loginForm.get('email');
    email?.valueChanges.forEach((value: string) => {
    });
  }

  checkValidPassword(value: any) {
    if (value && value.length > 7) {
      this.validPwd = true;
    } else {
      this.validPwd = false;
    }
  }

  loginPwdEnter() {
    const password = this.loginForm.get('password');
    password?.valueChanges.forEach((value: string) => {
      this.checkValidPassword(value);
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.checkValidPassword(this.loginForm.value.password);
      this.checkValidEmail(this.loginForm.value.email);
      return;
    } else {
      this.datastoreService.setUserName(this.loginForm.value.email.toLowerCase());
      this.loginService.login(this.loginForm.value.email.toLowerCase(), btoa(unescape(encodeURIComponent(this.loginForm.value.password)))).subscribe(
        async (data: any) => {
          this.datastoreService.setLoggedInEmail(this.loginForm.value.email.toLowerCase());
          this.errorDetails = false;
          this.loginAttempt = 0;
          this.datastoreService.setLoginAttempt(this.loginAttempt);
          this.getApplicationConfig(this.portalName);
          this.datastoreService.setContactPreferenceData(this.getContactPreference(data.body));
          this.datastoreService.setContactPreferenceBannerShown(true);
          this.datastoreService.setContactPreferencePopupShown(true);
          this.datastoreService.setCustomerLogin(data.body);
          await this.loginService.getCustomerDetail(data.body.customer_id).toPromise();
          this.updateEmailForFirstLogin(data.body);
        },
        (error: any) => {
          if (error.error.code === '002') {
            let errLockMsg = error.error.reason_code.indexOf('Account is locked');
            let errRecordMsg = error.error.reason_code.indexOf('Record not found');
            let errFailedMsg = error.error.reason_code.indexOf('Already failed');
            let errNoUserMsg = error.error.reason_code.indexOf('Cannot find a matching user');
            let errWSSUserNotFoundMsg = error.error.reason_code.indexOf('WSS user not found');
            if (errLockMsg !== -1) {
              this.nav.navigate(['attempts-exceeded']);
            } else if (errRecordMsg !== -1) {
              this.nav.navigate(['account-locked']);
            } else if (errNoUserMsg !== -1 || errWSSUserNotFoundMsg !== -1) {
              this.errorDetails = true;
              this.messageService.setBannerMessage(this.bannerMessage);
              this.changeDetectorRef.detectChanges();
            } else if (errFailedMsg !== -1) {
              this.errorDetails = true;
              this.changeDetectorRef.detectChanges();
            }
            this.messageService.setBannerMessage(this.bannerMessage);
          } else if (error.error.code === '001') {
            this.nav.navigate(['no-user']);
          } else {
          }
        },
      );
    }
  }

  getAuthorised(): void {
    this.loginService.getAuthorisation().subscribe((data: any) => {
      this.authorisation = data;
      let token = this.authorisation.headers.get('x-authorization');
      this.setHeaders(token);
      if (this.encryptedEmail) {
        this.loginService.getDecryptEmail(this.encryptedEmail).subscribe((val: any) => {
          this.loginForm.controls['email'].patchValue(val.decryptedValue)
        });
      }
    },
      (error: any) => {
        this.errorCode = error.error?.code ? error.error.code : error.status;
        this.showError(error.status);
      });
  }

  updateEmailForFirstLogin(loginResponse: any) {
    let todayDate = new Date().toISOString();
    let { date_Email_Updated, alias, is_Email_Updated } = loginResponse;
    if (date_Email_Updated == "") {
      this.loginService.UpdateContact({ email_address: alias }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.updateContactPreferenceOrEmailData(todayDate, true, loginResponse);
      },
        (error: any) => {
          this.updateContactPreferenceOrEmailData(todayDate, false, loginResponse);
        });
    } else {
      this.updateContactPreferenceOrEmailData(date_Email_Updated, is_Email_Updated, loginResponse);
    }
  }

  updateContactPreferenceOrEmailData(emailUpdatedDate: any, isEmailUpdated: boolean, loginResponse: any) {
    if (Number(loginResponse['marketing_Display']) <= 2) {
      loginResponse['marketing_Display'] = (Number(loginResponse["marketing_Display"]) + 1).toString();
    }
    loginResponse['date_Marketing'] = new Date().toISOString();
    loginResponse["date_Email_Updated"] = emailUpdatedDate;
    loginResponse["is_Email_Updated"] = isEmailUpdated;
    this.loginService.UpdateUserDetails(loginResponse).pipe(takeUntil(this.destroy$),
      finalize(() => {
        this.navigateAfterLogin(loginResponse);
      })
    ).subscribe();
  }

  getApplicationConfig(brandName: any) {
    this.loginService.getDefaultConfiguration().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.enabled`]) {
        let isFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.${brandName}.enabled`];
        this.datastoreService.setIsContactPreferenceFeature(isFeatureEnabled);
      } else {
        this.datastoreService.setIsContactPreferenceFeature(false);
      }
      if (response?.propertySources[0]?.source[`mfvs.feature.complaint-switch.enabled`]) {
        let isComplaintFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.complaint-switch.${brandName}.enabled`];
        this.datastoreService.setComplaintsFeature(isComplaintFeatureEnabled);
      } else {
        this.datastoreService.setComplaintsFeature(false);
      }
      this.changeDetectorRef.detectChanges();
    }, (error: any) => {
      if (error) {
        this.datastoreService.setComplaintsFeature(false);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getContactPreference(obj: any) {
    const { marketing_Display, marketing_Flag } = obj;
    return { marketingDisplayCount: Number(marketing_Display), marketingFlag: marketing_Flag };
  }

  private hasOneNumber(value: string) {
    if (value && value.match(this.numberformat)) {
      return true;
    } else {
      return false;
    }
  }

  private hasOneLowerCaseChar(value: string) {
    if (value && value.match(this.lowerCaseLetterformat)) {
      return true;
    } else {
      return false;
    }
  }

  private hasOneUpperCaseChar(value: string) {
    if (value && value.match(this.upperCaseLetterformat)) {
      return true;
    } else {
      return false;
    }
  }

  isLengthEightChars(value: string) {
    if (value.length >= 8) {
      return true;
    } else {
      return false;
    }
  }

  closeEmailInfoPopup() {
    this.showEmailInfoPopup = false;
  }

  showEmailInfo() {
    this.showEmailInfoPopup = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
