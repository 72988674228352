import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { DatastoreService } from "@wss/service/datastore.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class VoluntaryTerminationPageAccessRouteGuard implements CanActivate {
    accountDetails : any;
    isRegulated: boolean = false;
    isBereavementFileTag: boolean = false;

    constructor(private router: Router, private dataStoreService: DatastoreService) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.accountDetails = this.dataStoreService.getAccountDetails();
        this.isRegulated = this.accountDetails.regulated_ind ? true : false;
        this.isBereavementFileTag = this.accountDetails.file_tag === '13' ? true : false;
        if (!this.isRegulated || this.isBereavementFileTag) {
            this.router.navigate(['/eoc-options/cta-voluntary-termination']);
            return false;
        }
        return true;
    }    
}